//FONT
@import url("https://fonts.googleapis.com/css2?family=Comic+Neue:wght@300;700&display=swap");

$red: #ff000f;
$yellow: #ffd640;
$green: #0fff5a;
$black: #191c1d;

.no-selection {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}

body {
  font-family: "Comic Neue", cursive;
  background-color: $black;
  font-weight: normal;
  .container {
    margin-left: auto;
    margin-right: auto;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;

    .btn-container {
      margin-top: 20px;
      display: grid;
      grid-template-columns: 33% 34% 33%;
      text-align: center;
      align-items: center;
      background-color: $yellow;
      color: $black;
      .btn {
        padding: 8px 15px;
        background-color: $yellow;
        font-weight: bold;
        border: 0;
        border-radius: 4px;
        cursor: pointer;
      }
    }
  }
}
